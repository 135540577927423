<template>
  <div class="system">
    <div class="return" v-if="clienId" @click="back">
      <img class="backImg" src="@/assets/images/back.png" alt />
    </div>
    <div class="top">
      <div class="title">
        <i class="el-icon-collection-tag" />基本资料
      </div>
	  <div style="text-align: right;">


	  </div>
    </div>
    <!-- <el-form
      ref="detail"
      label-position="right"
      label-width="180px"
      :rules="rules"
      :model="detail"
      size="mini"
      class="formBox"
    > -->
    <el-form
      :rules="rules"
      :model="detail"
    >
    <el-descriptions class="margin-top" :column="4" border size="small">
      <el-descriptions-item>
        <template slot="label">销售部门</template>
        {{detail.shop_name}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">客户姓名</template>
        {{detail.consignee}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单单号</template>
        {{detail.refund_order_no}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">类型</template>
        <span v-if="detail.type==1">退款单</span>
        <span v-if="detail.type==2">退货单</span>
        <span v-if="detail.type==3">收款单</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="noKing == 1">
        <template slot="label">开单员姓名</template>
        <el-form-item size="mini"  prop="sale_id">
          <el-select
            v-model="detail.sale_id"
            class="w200"
            filterable
            clearable
            @change="changeOutreahc"
          >
            <el-option
              v-for="item in options.staff"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-descriptions-item>
      <el-descriptions-item v-else>
        <template slot="label">录单员姓名</template>
        {{detail.operator_name}}
      </el-descriptions-item>

	  <el-descriptions-item v-if="isShowBusinessOneType">
        <template slot="label">业务类型</template>
        <el-form-item  prop="business_type">
          <el-select v-model="detail.business_type" @change="business_type"   placeholder="请选择业务类型">
            <el-option
              v-for="item in options.business_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-descriptions-item>

      <el-descriptions-item v-if = "isShowBusinessTwoType">
        <template slot="label">业务类型分类</template>
        <el-form-item  >
          <el-select v-model="detail.business_two_type"   placeholder="请选择业务类型">
            <el-option
              v-for="item in options.business_two_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </el-descriptions-item>

      <el-descriptions-item v-if= "isShowProjectType">
          <template slot="label">项目类型</template>
          <el-form-item  prop="project_type">
          <el-select v-model="detail.project_type"  placeholder="请选择项目类型">
            <el-option
              v-for="item in options.project_type"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        </el-descriptions-item>

       <el-descriptions-item>
        <template slot="label">订单类型</template>
        <el-form-item  prop="order_type">
        <el-select v-model="detail.order_type"  placeholder="请选择订单类型">
          <el-option
            v-for="item in options.order_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      </el-descriptions-item>

      <el-descriptions-item v-if="isShowOrderClass">
	    <template slot="label">订单分类</template>
      <el-form-item size="mini" >
        <el-radio v-model="detail.order_class" label="1">生美</el-radio>
        <el-radio v-model="detail.order_class" label="2">医美</el-radio>
      </el-form-item>

	    <!-- {{detail.is_outreach}} -->
	  </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==2">
        <template slot="label">销售组织</template>
        {{detail.sales_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type!=2">
        <template slot="label">结算组织</template>
        {{detail.sales_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==2">
        <template slot="label">发货组织</template>
        {{detail.settlement_name}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type!=2">
        <template slot="label">收/退款组织</template>
        {{detail.settlement_name}}
      </el-descriptions-item>
      <el-descriptions-item>
          <template slot="label">金蝶单号</template>
          {{detail.king_num?detail.king_num:'暂无'}}
        </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">订单状态</template>
        <span v-if="detail.status == 0">待支付</span>
        <span v-if="detail.status == 1">已支付</span>
        <span v-if="detail.status == 2">已下推</span>
        <span v-if="detail.status == 3">已作废</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label" v-if="detail.type==1">退款总计</template>
        <template slot="label" v-if="detail.type==2">退货总计</template>
        <template slot="label" v-if="detail.type==3">收款总计</template>
        {{detail.total_amount}}
      </el-descriptions-item>
      <el-descriptions-item >
        <template slot="label">赠金</template>
        {{detail.gift_amount}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">本金</template>
        {{detail.pay_amount}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">店转</template>
        {{detail.shop_transfer_amount}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.is_treatment > 0">
        <template slot="label">转诊类型</template>
        {{detail.is_treatment == 1?'疗程':'非疗程'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.referral_shop_name">
        <template slot="label">转诊门店</template>
        {{detail.referral_shop_name?detail.referral_shop_name:"无"}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">成交时间</template>
        {{detail.deal_time?detail.deal_time:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">创建时间</template>
        {{detail.create_time?detail.create_time:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">金蝶推送状态</template>
        <span v-if="detail.king_status == 0">默认</span>
        <span v-if="detail.king_status == 1">成功</span>
        <span v-if="detail.king_status == 2">失败</span>
        <span v-if="detail.king_status == 5">登陆失败</span>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">销售政策编码</template>
        {{detail.sales_policy?detail.sales_policy:'暂无'}}
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1">
        <template slot="label">退款类型</template>
        <span v-if="detail.refund_type == '101.01'">退款店转</span>
        <span v-if="detail.refund_type == '101.02'">退款</span>
        <span v-if="detail.refund_type == '101.03'">退款调整</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1" >
        <template slot="label">退款审批类型</template>
        <span v-if="detail.combo == 0">暂无</span>
        <span v-if="detail.combo == 1">项目退款</span>
        <span v-if="detail.combo == 2">疤痕退款</span>
        <span v-if="detail.combo == 3">第三方分成</span>
      </el-descriptions-item>
      <el-descriptions-item v-if="detail.type==1">
      </el-descriptions-item>
      <el-descriptions-item :span="1">

      </el-descriptions-item>
      <el-descriptions-item :span="2">
        <template slot="label">状态描述</template>
        {{detail.status_msg}}
      </el-descriptions-item>

	  <el-descriptions-item>
	    <template slot="label">是否体验</template>
      <el-form-item size="mini" >
        <el-radio v-model="detail.is_experience" label="0">否</el-radio>
        <el-radio v-model="detail.is_experience" label="1">是</el-radio>
      </el-form-item>
	    <!-- {{detail.is_experience}} -->
	  </el-descriptions-item>
	  <el-descriptions-item>
	    <template slot="label">体验人</template>
      <el-form-item
        v-if="detail.is_experience == 1"
        size="mini"

        prop="experience_id"
      >
        <el-select
          v-model="detail.experience_id"
          class="w200"
          filterable
          clearable
          @change="changeExperiencc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
	    <!-- {{detail.experience_name}} -->
	  </el-descriptions-item>

	  <el-descriptions-item>
	    <template slot="label">是否外联</template>
      <el-form-item size="mini" >
        <el-radio v-model="detail.is_outreach" label="0">否</el-radio>
        <el-radio v-model="detail.is_outreach" label="1">是</el-radio>
      </el-form-item>

	    <!-- {{detail.is_outreach}} -->
	  </el-descriptions-item>
	  <el-descriptions-item>
	    <template slot="label">外联人</template>
      <el-form-item v-if="detail.is_outreach == 1" size="mini"  prop="outreahc_id">
        <el-select
          v-model="detail.outreahc_id"
          class="w200"
          filterable
          clearable
          @change="changeOutreahc"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
	    <!-- {{detail.outreahc_name}} -->
	  </el-descriptions-item>

	  <el-descriptions-item>
	    <template slot="label">是否转介绍</template>
      <el-form-item size="mini">
        <el-radio v-model="detail.is_introduction" label="0">否</el-radio>
        <el-radio v-model="detail.is_introduction" label="1">是</el-radio>
      </el-form-item>
	    <!-- {{detail.is_introduction}} -->
	  </el-descriptions-item>

    <el-descriptions-item v-if="isShowRefunder">
	    <template slot="label">退款人</template>
	      {{detail.refunder}}
	  </el-descriptions-item>
    <el-descriptions-item v-if="isShowRefundAccount">
	     <template slot="label">退款账号</template>
        {{detail.refund_account}}
	  </el-descriptions-item>

	  <el-descriptions-item>
	    <template slot="label">转介绍人</template>
      <el-form-item
        v-if="detail.is_introduction == 1"
        size="mini"
        prop="introduction_id"
      >
        <el-select
          v-model="detail.introduction_id"
          class="w200"
          filterable
          clearable
          @change="changeIntroduction"
        >
          <el-option
            v-for="item in options.staff"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
	    <!-- {{detail.introduction_name}} -->
	  </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">订单描述</template>
        {{detail.description?detail.description:'暂无'}}
      </el-descriptions-item>

	  <el-descriptions-item v-if="detail.type==2" >
	    <template slot="label">收入类型</template>
	    <span v-if="detail.income_type == 1">服务（各类疗程卡项及疗程卡项赠送产品）</span>
	    <span v-if="detail.income_type == 2">产品销售（单独付费购买产品）</span>
	    <span v-if="detail.income_type == 3">医美收入</span>
	  </el-descriptions-item>

    </el-descriptions>
  </el-form>
    <div class="top">
      <div class="title mt20">
        <i class="el-icon-collection-tag" />
        <span v-if="type == 1">退款单资料</span>
        <span v-if="type == 2 ">退货商品资料</span>
        <span v-if="type == 3 ">收款单资料</span>
      </div>
    </div>
    <!--列表-->
    <el-row>
      <el-col :span="24">
        <el-table
          v-if="type == 1 || type == 3"
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
        <el-table-column label="* 业务类型" width="400">
                <template scope="{ row, $index }">
                  <el-select   @change="(value) => handleChange(value,$index)" v-model="row.data_business_type" size="mini" placeholder="请选择" class="w100"  >
                    <el-option
                      v-for="item in options.business_type"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"


                    />
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="* 业务二级类型" width="400">
                <template scope="{ row, $index }">
                  <el-select                  :disabled="list[$index].data_business_type ==2 ?false:true"
                    v-model="list[$index].data_business_two_type" clearable  size="mini" placeholder="请选择" class="w100"  >
                    <el-option
                      v-for="item in options.business_two_type"
                        size="mini"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"

                    />
                  </el-select>
                </template>
              </el-table-column>
            <el-table-column label="方式">
                <template slot-scope="{ row }">
                  <span v-if="row.refund_mode == '01'">现金</span>
                  <span v-if="row.refund_mode == '02'">刷卡/微信/支付宝</span>
                  <span v-if="row.refund_mode == '03'">基本户收款</span>
                  <span v-if="row.refund_mode == '04'">分期</span>
                  <span v-if="row.refund_mode == '05'">平台收款</span>
                  <span v-if="row.refund_mode == '06'">店转</span>
                  <span v-if="row.refund_mode == '07'">对公银行账户付款</span>
                  <span v-if="row.refund_mode == '08'">个人银行账户付款</span>
                  <span v-if="row.refund_mode == '12'">内部结算</span>
                </template>
              </el-table-column>
            <el-table-column label="本金" width="220" prop="pay_price">
              <template slot-scope="{ row }">
                {{row.pay_price}}
              </template>
            </el-table-column>
            <el-table-column label="赠金" width="220" prop="gift_price"></el-table-column>
            <el-table-column label="店转" width="220" prop="shop_transfer_price"></el-table-column>
            <el-table-column label="银行号" width="220" prop="bank_number"></el-table-column>
            <el-table-column label="销售政策编码" width="220" prop="data_sales_policy"></el-table-column>
            <el-table-column label="描述" width="220" prop="descs"></el-table-column>

        </el-table>
        <el-table
          v-else
          :data="list"
          size="small"
          border
          :cell-style="$style.cellStyle"
          :header-cell-style="$style.rowClass"
        >
          <ElTableColumn label="商品名称" prop="goods_name" />
          <ElTableColumn label="商品名称-别名">
            <template slot-scope="{ row }">
              <el-input v-model="row.goods_alias_name" placeholder="请输入商品名称-别名"></el-input>
            </template>
          </ElTableColumn>
          <ElTableColumn label="商品含税单价" prop="pay_price" />
          <ElTableColumn label="商品规格金蝶编码" prop="kingdee_code" />
          <ElTableColumn label="是否赠品">
            <template slot-scope="{ row }">
              <span v-if="row.is_gift == '0'">否</span>
              <span v-if="row.is_gift == '1'">是</span>
            </template>
          </ElTableColumn>
          <ElTableColumn label="仓库编码" prop="house_code" />
          <ElTableColumn label="商品退款描述" prop="descs" />
        </el-table>
      </el-col>
    </el-row>
    <div class="btnBox" style>
        <el-button type="primary" size="mini" class="mainBtn" @click="submitForm()">完成，提交</el-button>
      </div>
  </div>
</template>

<script>
import { refundDetail, orderRefundDetail,delRefundOrder,updateDetail } from "@/api/order";
import { staffList } from "@/api/staff";
class Detail {
  type = 3;
  shop_id = ""; //店铺id
  // shop_name = ""; //店铺名称
  uid = ""; //客户id
  // consignee = ""; //客户名称
  // bank_number = ""; //店铺银行卡号
  // market_id = ""; //市场id
  // market_name = ""; //市场名称
  sale_id = ""; //开单人id
  sale_name = ""; //开单人名称
  // sales_organization = ""; // 销售组织编码
  // sales_name = ""; // 销售组织名称
  // settlement_organization = ""; // 发货组织编码
  // settlement_name = ""; // 发货组织名称
  is_experience = "0"; // 是否体验
  experience_id = ""; // 体验人id
  experience_name = ""; // 体验人姓名
  is_outreach = "0"; // 是否外联
  outreahc_id = ""; // 外联人id
  outreahc_name = ""; // 外联人姓名
  is_introduction = "0"; // 是否转介绍
  introduction_id = ""; // 转介绍id
  introduction_name = ""; // 转介绍姓名
  order_type = ""; //订单类型
  project_type = ""; //项目类型
  // refund_type = ""; //收款类型
  // deal_time = "";
  // sales_policy = "";
  // referral_shop_id = "";
  // referral_shop_name = "";
  // is_treatment = "2";
  business_type = "";
  business_two_type = "";
  // description = "";
  // shanghu_type = "2"; // 商户分类
  // refund_type_plus = "1"; // 收款类型2

  // pay_amount = 0;
  // gift_amount = 0;
  // total_amount = 0;

  // goodsList = [];
}
class Options {
  constructor() {
    this.uid = [];
    this.shop_id = [];
    this.market = [];
    this.business_type = [
      { id: 1, name: "疤痕" },
      { id: 2, name: "项目" },
      { id: 3, name: "青春痘" },
      { id: 4, name: "护肤品" }
    ];
    this.order_type = [
      { id: 1, name: "新单" },
      { id: 2, name: "补单" }
    ];
    this.refund_type = [
      { id: "101.02", name: "收款" },
      { id: "101.03", name: "收款调整" }
    ];

    this.refund_type_plus = [
      { id: "1", name: "通联支付" },
      { id: "2", name: "传统支付" },
      { id: "3", name: "通联远程" }
    ];
    this.project_type = [
        { id: 1, name: '手术' },
        { id: 2, name: '注射' },
        { id: 3, name: '光电' },
        { id: 4, name: '生美皮肤' },
        { id: 5, name: '身体' },
    ];
    this.business_two_type = [
      { id: 1, name: "身体项目" },
      { id: 2, name: "皮肤项目" },
    ];
    this.outreahc_id = [];
    this.staff = [];
    this.refundMode = [];
    this.sales_policy = [];
    this.referral_shop_id = [];
  }
}
class Rules {
  sale_id = [{ required: true, message: "请选择开单人", trigger: "change" }];
  sales_organization = [
    { required: true, message: "请选择销售组织", trigger: "change" }
  ];
  settlement_organization = [
    { required: true, message: "请选择发货组织", trigger: "change" }
  ];
  business_type = [
    { required: true, message: "请选择业务类型", trigger: "change" }
  ];
  order_type = [
    { required: true, message: "请选择订单类型", trigger: "change" }
  ];
  refund_type = [
    { required: true, message: "请选择收款类型", trigger: "change" }
  ];
  is_treatment = [
    { required: true, message: "请选择转诊类型", trigger: "change" }
  ];
  deal_time = [
    { required: true, message: "请选择业务日期", trigger: "change" }
  ];
  experience_id = [
    { required: true, message: "请选择体验人", trigger: "change" }
  ];
  outreahc_id = [
    { required: true, message: "请选择外联人", trigger: "change" }
  ];
  introduction_id = [
    { required: true, message: "请选择转介绍人", trigger: "change" }
  ];
  refund_type_plus = [
    { required: true, message: "请选择收款类型2", trigger: "change" }
  ];
}
export default {
  name: "system",
  data() {
    return {
      options: new Options(), // 选项
      rules: new Rules(), // 表单校验
      system: {},
      remark: "",
      detail: [],
      list: [],
      type: "",
      clienId: "",
      types: "",
      noKing: "",
      isShowBusinessOneType:false, //是否显示一级分类
      isShowBusinessTwoType:false, //是否显示二级分类
      isShowProjectType : false, //是否显示项目分类
      isShowOrderClass  : false, //是否显示订单分类
      isShowRefundAccount :false, //是否显示退款账号
      isShowRefunder: false //是否显示退款人
    };
  },
  mounted() {
    this.type = this.$route.query.type;
    this.types = this.$route.query.types;
    this.clienId = this.$route.query.clienId;
    this.noKing = this.$route.query.noKing,
    this.getDetail();
    this.getOptions();
  },
  methods: {
    business_type(e){
      if(e==2){
        this.isShowBusinessTwoType = true;
      }else{
        this.isShowBusinessTwoType = false;
        this.detail.business_two_type = "";
      }
    },

    // 获取详情
    getDetail() {
      // console.log(this.$route.query);
      refundDetail({
        order_id: this.$route.query.detail.id,
        type: this.$route.query.detail.type
      }).then(res => {
        this.detail = res;
        this.detail.is_introduction=res.is_introduction+"";
        this.detail.is_outreach=res.is_outreach+"";
        this.detail.is_experience=res.is_experience+"";
        if(res.business_type == "疤痕"){
          res.business_type=1;
        }else if(res.business_type == "项目"){
          res.business_type=2;
          this.isShowBusinessTwoType = true;
        }else if(res.business_type == "青春痘")
        {
          res.business_type=3;
        }else if(res.business_type == "护肤品"){
          res.business_type=4;
        }
        if(res.order_type == "新单"){
          res.order_type=1;
        }else if(res.order_type == "补单"){
          res.order_type=2;
        }
        if(this.detail.introduction_id == 0)
          {
            this.detail.introduction_id = "";
          }
          if(this.detail.experience_id == 0)
          {
            this.detail.experience_id = "";
          }
          if(this.detail.outreahc_id == 0)
          {
            this.detail.outreahc_id = "";
          }
          if(this.detail.project_type == 0)
          {
            this.detail.project_type = "";
          }
          if(this.detail.business_two_type == 0){
            this.detail.business_two_type = "";
          }
          if(this.detail.type == 2){
            this.isShowProjectType =true;
            // this.isShowBusinessTwoType = true;
            this.isShowBusinessOneType = true;

          }

          if(this.detail.type ==1){
            this.isShowRefundAccount = true;
            this.isShowRefunder = true;
          }




          if(this.detail.shop_type == 1 && (this.detail.type ==1 || this.detail.type ==3)){
            this.isShowOrderClass = true;
          }

        console.log(this.detail);
      });
      orderRefundDetail({
        order_id: this.$route.query.detail.id,
        type: this.$route.query.detail.type
      }).then(res => {
        this.list = res.goodsList;
      });
    },
    // 提示
    hint(type,text) {
      this.$confirm(`${text}，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if(type == 'del'){
            this.delOrder();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    // 作废订单
    delOrder(){
      delRefundOrder({ order_id: this.$route.query.id,status:3}).then(res => {
          this.$message({
            type: "success",
            message: res.msg
          });
          if(this.clienId){
            this.back()
          }else{
            this.$router.go(-1)
          }
      });
    },
    // 收款方式
    handleChange(value, index) {
      console.log(123213);
      this.list[index].data_business_two_type ='';



    },
    // 获取选项
    getOptions() {
      // 组织
      // shopMarket({ shopId: this.detail.shop_id }).then(res => {
      //   this.options.market = res;
      //   this.detail.sales_organization = this.options.market[0].marketKingdeeCode;
      //   this.detail.sales_name = this.options.market[0].marketName;
      //   this.detail.settlement_organization = this.options.market[0].marketKingdeeCode;
      //   this.detail.settlement_name = this.options.market[0].marketName;
      // });
      // // 促销政策
      // saleList().then(res => {
      //   this.options.sales_policy = res.data.list;
      // });
      // 该门店员工
      staffList({ page: 1, limit: 100000, shopId: this.detail.shop_id }).then(
        res => {
          this.options.staff = res.list;
        }
      );
      // 该门店详情
      // shopDetail({ shopId: this.detail.shop_id }).then(res => {
      //   this.detail.bank_number = res.bankCard;
      //   this.detail.market_id = res.marketId;
      //   this.detail.market_name = res.marketName;
      //   this.isSichuan = res.isSichuan;
      //   this.shopType = res.shopType;
      //   if (this.shopType == 1) {
      //     this.getSecondShop(this.$route.query.detail.telephone);
      //   }
      // });
      // // 收款方式列表
      // refundMode({ refundMode: this.detail.refund_type_plus }).then(res => {
      //   this.options.refundMode = res.data.list;
      // });

      // // 获取登录人的信息
      // getUserInfo().then(res =>{
      //   console.log('个人信息返回值：'+res.shop_type);
      //   this.userShopType = res.shop_type;
      //   this.userIsUse = res.is_use;
      // });
    },
    submitForm(){
      if(this.detail.order_type!=1&&this.detail.order_type!=2)
      {
            this.$message({
              type: "warning",
              message: "数据填写错误"
            });
      }else{
        // console.log(this.list);
        //   return false;
        updateDetail({

          id:this.detail.id,
          business_type:this.detail.business_type,
          business_two_type:this.detail.business_two_type,
          order_type:this.detail.order_type,
          is_introduction:this.detail.is_introduction,
          introduction_id:this.detail.introduction_id,
          is_outreach:this.detail.is_outreach,
          outreahc_id:this.detail.outreahc_id,
          is_experience:this.detail.is_experience,
          experience_id:this.detail.experience_id,
          project_type:this.detail.project_type,
          order_class:this.detail.order_class,
          order_goods:this.list

        }).then(
          res => {
            if(res.code==200)
            {
              this.$message({
                type: "success",
                message: res.data.msg
              });
              this.$router.back();
            }else{
                this.$message({
                type: "warning",
                message: res.msg
              });
            }
          });
      }
    },
	//编辑 资料
	/* openDialog(type, row) {
		console.log(11111);
		console.log(this.detail);
	 const query = { detail: this.detail };
	 switch (type) {
	   case "修改订单":
	     this.$router.push({ name: "修改订单", query });
	     break;
	  }
	}, */

    // 返回
    back() {
      const query = {
        id: this.$route.query.clienId,
        customer_name: this.$route.query.detail.customer_name,
        detail:this.$route.query.detail,
        activeName: this.$route.query.types
      };
      this.$router.push({ name: "客户详情", query });
    }
  }
};
</script>

<style lang='scss' scoped>
.return {
  position: fixed;
  right: 20px;
  top: 71px;
  z-index: 999;
  .backImg {
    margin-right: 14px;
    width: 32px;
    height: 100%;
    cursor: pointer;
    vertical-align: middle;
  }
}
.btnBox {
  width: 100%;
  margin-top: 20px;
  text-align: right;
}
.top {
  display: flex;
  justify-content: space-between;
  .title {
    font-weight: bold;
    font-size: 14px;
    line-height: 34px;
    color: #666;
  }
}
.formBox {
  display: flex;
  flex-wrap: wrap;
  .el-form-item {
    width: 25%;
  }
}
::v-deep .el-descriptions-item__label.is-bordered-label {
  background: #f9fafc;
}

</style>
